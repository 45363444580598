





const geofences = [
    {
        "id": 1,
        "uid": 3,
        "name": "One Environmental Inc.",
        "description": "One Environmental Inc. 261131 Wagon Wheel Way Calgary, Alberta, T4A 0E2",
        "tags": "One Env.",
        "type": "radius",
        "coordinates": {
            "center": {
                "lat": "51.05011",
                "lng": "-114.08529"
            },
            "radius": 500
        },
        "owner": "1",
        "rule": "inside",
        "orgId": "6"
    },
    {
        "id": 2,
        "uid": 4,
        "name": "Harbor Environmental Group Ltd",
        "description": "Harbour Environmental Group(Calgary) Ltd. 9595 Enterprise Way SE Calgary, Alberta",
        "tags": "Harbor Env.",
        "type": "radius",
        "coordinates": {
            "center": {
                "lat": "50.9659687",
                "lng": "-113.897286"
            },
            "radius": 500
        },
        "owner": "1",
        "rule": "inside",
        "orgId": "6"
    },
    {
        "id": 3,
        "uid": 5,
        "name": "Recycle West",
        "description": "Recycle West , 5366 55 Street SE, Calgary, Alberta,",
        "tags": "Recycle West",
        "type": "radius",
        "coordinates": {
            "center": {
                "lat": "51.0055",
                "lng": -113.9504782
            },
            "radius": 500
        },
        "owner": "1",
        "rule": "inside",
        "orgId": "6"
    }
]


/** An Object that contains Daily Report Summaries */
let dailyReportData = {
    // Store a collection of Geofence Events 
    deviceInfo: {
        device: "10123125e928e0", 
        name: "717422", 
        branch_id: "1"

    }, 
    reportSummaryInfo: {
        start: 1637931623000, // Stores a Date for the report
        end: 1638018023000,
        id: "", // A Larger Report Summary ID. 
    }, 
    geofenceEvents: [
        {
            "time": "2021-11-26T23:45:03.353Z",
            "device": "10123125e928e0",
            "inside": true,
            "lat": 50.99885,
            "lng": -113.99123,
            "message": "717411 Arrived at Depot 1: LCB Calgary",
            "name": "enterDepot",
            "tag1": "event",
            "type": "System",
            "branch_id": "1"
        },
        {
            "time": "2021-11-26T20:52:52.110Z",
            "device": "10123125e928e0",
            "inside": true,
            "lat": 50.99876,
            "lng": -113.99024,
            "message": "717411 Left Depot 1: LCB Calgary",
            "name": "leaveDepot",
            "tag1": "event",
            "type": "System",
            "branch_id": "1"
        },
        {
            "time": "2021-11-26T19:10:13.773Z",
            "device": "101228594ab50c",
            "inside": true,
            "lat": 50.9992,
            "lng": -113.9953,
            "message": "717403 Arrived at Depot 1: LCB Calgary",
            "name": "enterDepot",
            "tag1": "event",
            "type": "System",
            "branch_id": "1"
        }
    ],
    jobs:[
        {
            "id": 2464,
            "truck": "717411",
            "location": {
                "lat": 50.99938895833331,
                "lng": -113.99529624999998
            },
            "start": 1637958840000,
            "end": 1637959919091,
            "status": "pending",
            "device": "10123125e928e0",
            "branch": "LCB Calgary",
            "branch_id": "1",
            "address": "Western Headworks Canal Pathway, Calgary, AB T2C 4V2, Canada",
            "confidence": 2
        },
        {
            "id": 2465,
            "truck": "717411",
            "location": {
                "lat": 50.8859675,
                "lng": -113.96959000000001
            },
            "start": 1637960640000,
            "end": 1637960783754,
            "status": "pending",
            "device": "10123125e928e0",
            "branch": "LCB Calgary",
            "branch_id": "1",
            "address": "Deerfoot Trail SE, Calgary, AB T3M 2N9, Canada",
            "confidence": 2
        },
        {
            "id": 2466,
            "truck": "717411",
            "location": {
                "lat": 50.882335,
                "lng": -113.95773
            },
            "start": 1637960995185,
            "end": 1637961026941,
            "status": "pending",
            "device": "10123125e928e0",
            "branch": "LCB Calgary",
            "branch_id": "1",
            "address": "Seton Boulevard SE, Calgary, AB T3M 1T4, Canada",
            "confidence": 2
        },
        {
            "id": 2467,
            "truck": "717411",
            "location": {
                "lat": 50.69964125,
                "lng": -113.97574374999999
            },
            "start": 1637962519768,
            "end": 1637962604845,
            "status": "pending",
            "device": "10123125e928e0",
            "branch": "LCB Calgary",
            "branch_id": "1",
            "address": "Okotoks Country Living and Garden Center, 386105 386 Avenue W, Okotoks, AB T1S 2N7, Canada",
            "confidence": 2
        },
        {
            "id": 2468,
            "truck": "717411",
            "location": {
                "lat": 50.684243333333335,
                "lng": -114.00172055555556
            },
            "start": 1637962923840,
            "end": 1637963072906,
            "status": "pending",
            "device": "10123125e928e0",
            "branch": "LCB Calgary",
            "branch_id": "1",
            "address": "Meridian Street, AB T1S 1A1, Canada",
            "confidence": 2
        },
        {
            "id": 2469,
            "truck": "717411",
            "location": {
                "lat": 50.68223256250001,
                "lng": -114.00196822916664
            },
            "start": 1637962740000,
            "end": 1637964459579,
            "status": "pending",
            "device": "10123125e928e0",
            "branch": "LCB Calgary",
            "branch_id": "1",
            "address": "Meridian Street, AB T1S 1A1, Canada",
            "confidence": 9
        },
        {
            "id": 2470,
            "truck": "717411",
            "location": {
                "lat": 50.68441,
                "lng": -114.00173000000001
            },
            "start": 1637964544838,
            "end": 1637964570674,
            "status": "pending",
            "device": "10123125e928e0",
            "branch": "LCB Calgary",
            "branch_id": "1",
            "address": "Meridian Street, AB T1S 1A1, Canada",
            "confidence": 2
        },
        {
            "id": 2471,
            "truck": "717411",
            "location": {
                "lat": 50.686279,
                "lng": -114.00223783333333
            },
            "start": 1637962920000,
            "end": 1637965585140,
            "status": "pending",
            "device": "10123125e928e0",
            "branch": "LCB Calgary",
            "branch_id": "1",
            "address": "Meridian Street, AB T1S 1A1, Canada",
            "confidence": 7
        },
        {
            "id": 2472,
            "truck": "717411",
            "location": {
                "lat": 50.68425,
                "lng": -114.00173666666666
            },
            "start": 1637964300000,
            "end": 1637966090440,
            "status": "pending",
            "device": "10123125e928e0",
            "branch": "LCB Calgary",
            "branch_id": "1",
            "address": "Meridian Street, AB T1S 1A1, Canada",
            "confidence": 2
        },
        {
            "id": 2473,
            "truck": "717411",
            "location": {
                "lat": 50.68859,
                "lng": -113.97521
            },
            "start": 1637966376169,
            "end": 1637966418324,
            "status": "pending",
            "device": "10123125e928e0",
            "branch": "LCB Calgary",
            "branch_id": "1",
            "address": "402 Avenue E, AB T1S 1A1, Canada",
            "confidence": 2
        },
        {
            "id": 2474,
            "truck": "717411",
            "location": {
                "lat": 50.97173,
                "lng": -113.98165666666667
            },
            "start": 1637968164249,
            "end": 1637968196120,
            "status": "pending",
            "device": "10123125e928e0",
            "branch": "LCB Calgary",
            "branch_id": "1",
            "address": "Barlow Trail SE, Calgary, AB T2C 4R5, Canada",
            "confidence": 2
        },
        {
            "id": 2475,
            "truck": "717411",
            "location": {
                "lat": 50.99895,
                "lng": -113.95963499999999
            },
            "start": 1637968652274,
            "end": 1637968705081,
            "status": "pending",
            "device": "10123125e928e0",
            "branch": "LCB Calgary",
            "branch_id": "1",
            "address": "61 Avenue SE, Calgary, AB T2C 4P6, Canada",
            "confidence": 2
        },
        {
            "id": 2476,
            "truck": "717411",
            "location": {
                "lat": 51.004565,
                "lng": -113.9492175
            },
            "start": 1637968896529,
            "end": 1637969139463,
            "status": "pending",
            "device": "10123125e928e0",
            "branch": "LCB Calgary",
            "branch_id": "1",
            "address": "Cordy Oilfield Services, 5366 55 Street SE, Calgary, AB T2C 4R3, Canada",
            "confidence": 2
        },
        {
            "id": 2477,
            "truck": "717411",
            "location": {
                "lat": 51.006581777777775,
                "lng": -113.94973266666668
            },
            "start": 1637969245051,
            "end": 1637969763708,
            "status": "pending",
            "device": "10123125e928e0",
            "branch": "LCB Calgary",
            "branch_id": "1",
            "address": "Cordy Oilfield Services, 5366 55 Street SE, Calgary, AB T2C 4R3, Canada",
            "confidence": 7
        },
        {
            "id": 2478,
            "truck": "717411",
            "location": {
                "lat": 51.004599999999996,
                "lng": -113.949315
            },
            "start": 1637969890707,
            "end": 1637969975288,
            "status": "pending",
            "device": "10123125e928e0",
            "branch": "LCB Calgary",
            "branch_id": "1",
            "address": "Cordy Oilfield Services, 5366 55 Street SE, Calgary, AB T2C 4R3, Canada",
            "confidence": 2
        },
        {
            "id": 2479,
            "truck": "717411",
            "location": {
                "lat": 50.99921,
                "lng": -113.99495
            },
            "start": 1637970345690,
            "end": 1637970377857,
            "status": "pending",
            "device": "10123125e928e0",
            "branch": "LCB Calgary",
            "branch_id": "1",
            "address": "Western Headworks Canal Pathway, Calgary, AB T2C 4V2, Canada",
            "confidence": 2
        },
        {
            "id": 2480,
            "truck": "717411",
            "location": {
                "lat": 50.99955666666667,
                "lng": -113.99521
            },
            "start": 1637970462560,
            "end": 1637971065226,
            "status": "pending",
            "device": "10123125e928e0",
            "branch": "LCB Calgary",
            "branch_id": "1",
            "address": "Western Headworks Canal Pathway, Calgary, AB T2C 4V2, Canada",
            "confidence": 2
        }
    ], 
    jobReports: [
        {
            "id": 1163,
            "title": "Job #2469: Meridian Street Foothills County AB",
            "path": "templates/jobreportv3.html",
            "timestamp": 1637962740000,
            "type": "Job Report",
            "tags": "717411,Foothills County,AB,",
            "truck": "717411",
            "data": "",
            "start": 1637962740000,
            "template": 1637964459579,
            "score": "Good",
            "metadata": ""
        },
        {
            "id": 1164,
            "title": "Job #2471: Meridian Street Foothills County AB",
            "path": "templates/jobreportv3.html",
            "timestamp": 1637962920000,
            "type": "Job Report",
            "tags": "717411,Foothills County,AB,",
            "truck": "717411",
            "data": "",
            "start": 1637962920000,
            "template": 1637965585140,
            "score": "Poor",
            "metadata": ""
        },
        {
            "id": 1165,
            "title": "Job #2477: 55 Street SE Calgary AB",
            "path": "templates/jobreportv3.html",
            "timestamp": 1637969245051,
            "type": "Job Report",
            "tags": "717411,Calgary,AB,",
            "truck": "717411",
            "data": "",
            "start": 1637969245051,
            "template": 1637969763708,
            "score": "Poor",
            "metadata": ""
        }
    ]
}


export {
   geofences,
   dailyReportData 
}