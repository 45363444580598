<template>
  <div>
    <CRow>
      <CCol> </CCol>
    </CRow>

    <CRow class="my-0 py-0" v-if="!loading">
      <CCol class="pb-0">
        <CCard class="border-left pb-0" v-if="reportData"  accent-color="danger">
          <CCardHeader text-color="light" color="gradient-dark">
            <span
              ><strong>
                <CIcon name="cil-calendar" size="xl"> </CIcon> Day Summary for
                Truck {{ reportData.deviceInfo.name }}</strong
              ></span
            >
            <span class="float-right">
              <CButtonGroup class="align-right float-right" v-show="false">
                <span class="px-1">
                  <CButton
                    color="info"
                    square
                    size="sm"
                    class="m-0 p-1"
                    @click="refresh"
                  >
                    <div style="">
                      <CIcon name="cil-sync" class="h-100 m-0 p-0" />
                      <span class="color:white pull-right"></span>
                    </div>
                  </CButton>
                </span>
              </CButtonGroup>
            </span>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol md="6">
                <div class="p-0">
                  <p v-if="branchInfo">
                    <span class="float-left pb-4"
                      ><CAvatar
                        :initials="branchInfo.metadata.code"
                        :username="branchInfo.name"
                      />
                    </span>
                    <span class="pl-3">Branch: {{ branchInfo.name }}</span>
                  </p>
                </div>
                <br />

                <div>
                  <p></p>
                  <p>
                    <CIcon name="cil-truck" size="xl" />
                    <span><h5>TRUCK {{ reportData.deviceInfo.name }}</h5></span>
                    
                    <span><small>id: {{ reportData.deviceInfo.device }}</small></span>
                  </p>

                  <p>
                    <CIcon name="cil-calendar" size="xl" />
                    Date: {{ getDate(reportData.reportSummaryInfo.start) }}
                  </p>
                  <p>
                    <CIcon name="cil-clock" size="xl" />
                    Time: Start
                    {{ getTime(start) }} End
                    {{ getTime(end) }}
                  </p>
                </div>
              </CCol>
              <CCol>
                <CScoreCard
                  class="p-0"
                  :score="performanceSummaryData.score"
                  title="Safety Score"
                  :message="performanceSummaryData.message"
                >
                </CScoreCard>
              </CCol>
              

            </CRow>
            <CRow>
              <CCol col="12">
                <GrafanaEmbeddedPanel v-show="deviceInfo"
                :key="loading"
                :to="end"
                :from="start"
                :selected="deviceInfo"
                src="https://demo1.aarcomm.io/api/grafana/d/SzurgKYVk/test-clean-harbors-route-report-v1?orgId=1"
                >

                </GrafanaEmbeddedPanel>
              </CCol>
            </CRow>
            
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <!--GrafanaEmbeddedDashboard /-->
      </CCol>
    </CRow>

    <div class="card" v-if="timeline && timeline.length > 0" style="min-width:500px">
      <div v-for="item of timeline" :key="item.id" class="p-0 m-0">
        <CCard
          :collapsed="true"
          :collapsible="true"
          v-if="item.event"
          class="admin-card"
          
        >
           <CCardHeader >
            <CIcon name="cil-location-pin" />
            <span class="card-header-text"> Geofence Event </span>
            <span class="float-right">
              {{ getTime(item.timestamp || item.time) }}
            </span>
          </CCardHeader>
            <CCardBody class="m-0 p-0 ml-3" @dblclick="handleViewClick(item)">
              <CCallout color="var(--info)" >
              <CMedia 
                class=""
                :aside-image-props="{
                  src: '/Mapbox_dark_sample.jpg',
                  height: 100,
                  width: 175,
                }"
              >
                <h4>
                  <span class="p-0">{{ item.event.message }}</span>
                </h4>
                <span class="p-0">
                  <CGeocode :lat="item.event.lat" :lon="item.event.lng"
                /></span>
              </CMedia>
            </CCallout>
            </CCardBody>
          
        </CCard>

        <CCard
          :collapsed="false"
          :collapsible="true"
          class="admin-card mt-0"
          
          v-else-if="item.report"
        >
          <CCardHeader>
            <CIcon name="cil-chart" /> <span class="card-header-text">Job Report: {{ item.report.title }}</span>
            <span class="float-right">
              {{ getTime(item.timestamp) }}
            </span>
          </CCardHeader>
          <CCardBody>
            <CCollapse :show="Boolean(true)" :duration="collapseDuration">
              <CCardBody class="mt-0 p-0" @dblclick="handleViewClick(item)">
                <CMedia
                  :aside-image-props="{
                    src: '/Mapbox_dark_sample.jpg',
                    height: 100,
                    width: 175,
                  }"
                >
                  <CRow>
                    <CCol>
                      <h4>
                        {{ item.report.title }}
                      </h4>
                      <div>
                        <span class="text-muted">
                          Date Performed: {{ getDate(item.timestamp) }}
                        </span>
                        <br />
                        <span class="text-muted">
                          Time In: {{ getTime(item.timestamp) }}
                        </span>
                        <span class="text-muted" v-if="item.report.end">
                          Time Out: {{ getTime(item.report.end) }}
                        </span>
                      </div>
                      <CButton
                        size=""
                        color="info"
                        class=""
                        @click.stop="handleViewClick(item)"
                      >
                        View Details
                      </CButton>
                      <CButton
                        v-if="false"
                        size="sm"
                        color="danger"
                        class="ml-1"
                        @click="showWarning(item)"
                      >
                        Delete
                      </CButton>
                    </CCol>
                    <CCol col="4">
                      <CScoreCard
                        class="p-0"
                        :score="getPerformanceScore(item.report)"
                        title="Safety Score"
                      />
                    </CCol>
                  </CRow>
                </CMedia>
              </CCardBody>
            </CCollapse>
          </CCardBody>
        </CCard>
      </div>
    </div>
    <div v-else>
      <CCard>
        <CCardBody>
          <h3>No Events to Show</h3>
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<style scoped>
.card-header-text{
  font-size: 14px;
  font-weight: 500;
}
</style>

<script>

import GrafanaEmbeddedDashboard from "@/components/grafana/GrafanaEmbeddedDashboard";
import GrafanaEmbeddedPanel from "@/components/grafana/GrafanaEmbeddedPanel.vue";
import CGeocode from "@/components/base/CGeocode";
import CScoreCard from "@/components/reports/CScoreCard";
import CAvatar from "@/components/base/CAvatar";
import { dailyReportData } from "@/data/demoData";
import reportsData from "@/data/ReportData";
import CCallout from "@/components/base/CCallout.vue";

export default {
  name: "DailyReportSummaryView",
  components: {
    CAvatar,
    GrafanaEmbeddedDashboard,
    GrafanaEmbeddedPanel,
    CGeocode,
    CScoreCard,
    CCallout
},
  data() {
    return {
      deviceInfo: undefined, 
      collapseDuration: 300,
      warningModal: false,
      warningMsg: "",
      threshold: 1,
      timeline: undefined,
      reportData: undefined,
      loading: false,
      summary: undefined, 
    };
  },
  computed: {
    reportList() {
      return this.reportData.jobReports;
    },
    start() {
      if (this.timeline && this.timeline.length > 0) {
        return this.timeline[0].timestamp;
      }
      else
      return undefined; 
    },
    end() {
      if (this.timeline && this.timeline.length > 0) {
        return this.timeline.slice(-1)[0].timestamp;
      }
      else
      return undefined; 
    },
    

    branchInfo() {
      if (this.reportData && this.$store.getters.branches) {
        return this.$store.getters.branches.find(
          (item) => item.branch_id === this.reportData.deviceInfo.branch_id
        );
      }
    },
    performanceSummaryData() {
      if (this.summary) {
        return this.summary;
      } else if (this.reportData) {
        let report = this.reportData.jobReports[0]; // Just Take the first one for now
        return report;
      } else {
        return {
          score: "unknown",
          message: "Score Could not be Calculated",
        };
      }
    },
  },
  methods: {
    handleViewClick(item, e) {
      // TODO: Navigate to the Report
      this.showInRouter(item.report);
    },
    showInRouter(item) {
      let params = {
        id: item.id || item.ID,
        //title: item.title,
        //path: item.path,
      };
      this.$router.push({ path: "/reports/view", query: params });
    },
    showWaring(item, index) {},
    onDeleteClick(item, index) {},
    getBadge(status) {
      switch (status) {
        case "Good":
          return "success";
        case "Satisfactory":
          return "warning";
        case "Poor":
          return "danger";
        case "Very Poor":
          return "danger";
        default:
          "primary";
      }
    },
    getScoreEnum(status) {
      switch (status) {
        case "Good":
          return 4;
        case "Satisfactory":
          return 3;
        case "Poor":
          return 2;
        case "Very Poor":
          return 1;
        default:
          return 1;
      }
    },
    enumFromScore(status) {
      switch (status) {
        case 4:
          return "Good";
        case 3:
          return "Satisfactory";
        case 2:
          return "Poor";
        case 1:
          return "Very Poor";
        default:
          return "Unknown";
      }
    },
    getPerformanceScore(item) {
      return item.score;
    },
    getTime(myDate, options) {
      // TODO: Choose Data / Time Format based on region
      return this.$moment(parseInt(myDate)).format("HH:mm:ss A");

      // return new Date(parseInt(myDate)).toTimeString("en-US", options);
    },
    getDate(myDate, options) {
      if (!options)
        options = {
          weekday: "short",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
      return new Date(parseInt(myDate)).toLocaleDateString("en-US", options);
    },
    async refresh() {
      try {
        console.log("Refreshing");
        //let reports = await this.$app.loadReports();
        await this.load();
      } catch (err) {
        console.log(err);
      }
    },

    summarizePerformance(data) {
      try {
        console.log("Summarizing Data"); 
        let jobs = data.jobReports;
        let score = 0;
        let count = jobs.length;
        for (var job of jobs) {
          score += this.getScoreEnum(job.score);
        }
        score = parseInt(Math.ceil(score / count));
        console.log(score);
        if (isNaN(score)) {
          score = 0;
        }
        let ret = {
          score: this.enumFromScore(score),
          message: `Based on Average Score Between ${count} Jobs`,
        };
        return ret;
      } catch (err) {
        console.log(err);
      }
    },
    async load() {
      try {
        this.loading = true;
        let params = {
          device: "10123125e928e0",
          branch_id: "1",
          start: "now()-10d",
          end: "now()",
          order: "desc",
          limit: 100,
        };

        let timestamp = parseInt(this.$route.query.start); // Beginning of query
        let device = this.$route.query.device;
        let start = timestamp; //timestamp - (timestamp % (1000 * 3600 * 24));
        let end = start + (1000 * 3600 * 24);
        let devices = await this.$app.loadInventory();
        let deviceInfo = devices.find((item) => item.device === device);

        this.reportData = {
          deviceInfo: deviceInfo,
          reportSummaryInfo: {
            start: start,
            end: end,
          },
          geofenceEvents: [],
          jobs: undefined,
          jobReports: [],
        };
        this.deviceInfo = deviceInfo; 
        let name = deviceInfo.name;
        let query = {
          device: device,
          start: start,
          end: end,
        };
        let res = await this.$api.getGeofenceEvents(query);
        let data;
        if (res && res.status === 200) {
          data = res.data;
          this.reportData.geofenceEvents = data
            ? data.filter((item) => item.device === device)
            : [];
        }

        console.log(this.reportData.geofenceEvents);

        res = await this.$api.getJobs(query);
        if (res && res.status === 200) {
          data = res.data;

          this.reportData.jobs = data
            ? data.filter(
                (item) => item.device === device || item.truck === name
              )
            : [];
        }
        delete query.device; // Ignore this for now.
        res = await this.$api.getReports(query);
        if (res && res.status === 200) {
          data = res.data;
          this.reportData.jobReports = data
            ? data.filter(
                (item) => item.device === device || item.truck === name
              )
            : [];
        }
        console.log(this.reportData);
        this.summary = this.summarizePerformance(this.reportData);
        //let geofenceEvents = await this.$app.getGeofenceEvents(params);

        //console.log(geofenceEvents);
        //this.reportData = dailyReportData;
        this.timeline = this.sortEvents(this.reportData);

        
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    sortEvents(data) {
      try {
        let device = data.deviceInfo.device;
        let name = data.deviceInfo.name;
        let geofenceEvents = data.geofenceEvents
          ? data.geofenceEvents.filter((item) => item.device === device)
          : [];

        let jobs = data.jobs
          ? data.jobs.filter((item) => item.confidence >= this.threshold)
          : [];
        let reports = data.jobReports
          ? data.jobReports.filter(
              (item) => item.device === device || item.truck === name
            )
          : [];
        let collection = [];
        let temp;
        for (var event of geofenceEvents) {
          temp = {
            event: event,
            timestamp: new Date(event.time).getTime(),
            type: "event",
          };
          collection.push(temp);
        }
        for (var job of jobs) {
          temp = {
            job: job,
            timestamp: new Date(job.start).getTime(),
            type: "job",
          };
          collection.push(temp);
        }
        for (var report of reports) {
          temp = {
            report: report,
            timestamp: new Date(report.timestamp).getTime(),
            type: "report",
          };
          collection.push(temp);
        }
        console.log(collection);
        collection = collection.filter(item=>{return isNaN(item.timestamp)===false})
        collection = collection.sort((a, b) => a.timestamp - b.timestamp);
        console.log(collection);
        return collection;
      } catch (err) {
        console.log(err);
      }
    },
  },
  async activated() {
    this.load();
  },
  async mounted() {
    //let reports = await this.$app.loadReports();
    this.load();
  },
};
</script>