var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CRow", [_c("CCol")], 1),
      !_vm.loading
        ? _c(
            "CRow",
            { staticClass: "my-0 py-0" },
            [
              _c(
                "CCol",
                { staticClass: "pb-0" },
                [
                  _vm.reportData
                    ? _c(
                        "CCard",
                        {
                          staticClass: "border-left pb-0",
                          attrs: { "accent-color": "danger" }
                        },
                        [
                          _c(
                            "CCardHeader",
                            {
                              attrs: {
                                "text-color": "light",
                                color: "gradient-dark"
                              }
                            },
                            [
                              _c("span", [
                                _c(
                                  "strong",
                                  [
                                    _c("CIcon", {
                                      attrs: {
                                        name: "cil-calendar",
                                        size: "xl"
                                      }
                                    }),
                                    _vm._v(
                                      " Day Summary for Truck " +
                                        _vm._s(_vm.reportData.deviceInfo.name)
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _c(
                                "span",
                                { staticClass: "float-right" },
                                [
                                  _c(
                                    "CButtonGroup",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: false,
                                          expression: "false"
                                        }
                                      ],
                                      staticClass: "align-right float-right"
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "px-1" },
                                        [
                                          _c(
                                            "CButton",
                                            {
                                              staticClass: "m-0 p-1",
                                              attrs: {
                                                color: "info",
                                                square: "",
                                                size: "sm"
                                              },
                                              on: { click: _vm.refresh }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {},
                                                [
                                                  _c("CIcon", {
                                                    staticClass:
                                                      "h-100 m-0 p-0",
                                                    attrs: { name: "cil-sync" }
                                                  }),
                                                  _c("span", {
                                                    staticClass:
                                                      "color:white pull-right"
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "CCardBody",
                            [
                              _c(
                                "CRow",
                                [
                                  _c("CCol", { attrs: { md: "6" } }, [
                                    _c("div", { staticClass: "p-0" }, [
                                      _vm.branchInfo
                                        ? _c("p", [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "float-left pb-4"
                                              },
                                              [
                                                _c("CAvatar", {
                                                  attrs: {
                                                    initials:
                                                      _vm.branchInfo.metadata
                                                        .code,
                                                    username:
                                                      _vm.branchInfo.name
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "pl-3" },
                                              [
                                                _vm._v(
                                                  "Branch: " +
                                                    _vm._s(_vm.branchInfo.name)
                                                )
                                              ]
                                            )
                                          ])
                                        : _vm._e()
                                    ]),
                                    _c("br"),
                                    _c("div", [
                                      _c("p"),
                                      _c(
                                        "p",
                                        [
                                          _c("CIcon", {
                                            attrs: {
                                              name: "cil-truck",
                                              size: "xl"
                                            }
                                          }),
                                          _c("span", [
                                            _c("h5", [
                                              _vm._v(
                                                "TRUCK " +
                                                  _vm._s(
                                                    _vm.reportData.deviceInfo
                                                      .name
                                                  )
                                              )
                                            ])
                                          ]),
                                          _c("span", [
                                            _c("small", [
                                              _vm._v(
                                                "id: " +
                                                  _vm._s(
                                                    _vm.reportData.deviceInfo
                                                      .device
                                                  )
                                              )
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "p",
                                        [
                                          _c("CIcon", {
                                            attrs: {
                                              name: "cil-calendar",
                                              size: "xl"
                                            }
                                          }),
                                          _vm._v(
                                            " Date: " +
                                              _vm._s(
                                                _vm.getDate(
                                                  _vm.reportData
                                                    .reportSummaryInfo.start
                                                )
                                              ) +
                                              " "
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "p",
                                        [
                                          _c("CIcon", {
                                            attrs: {
                                              name: "cil-clock",
                                              size: "xl"
                                            }
                                          }),
                                          _vm._v(
                                            " Time: Start " +
                                              _vm._s(_vm.getTime(_vm.start)) +
                                              " End " +
                                              _vm._s(_vm.getTime(_vm.end)) +
                                              " "
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ]),
                                  _c(
                                    "CCol",
                                    [
                                      _c("CScoreCard", {
                                        staticClass: "p-0",
                                        attrs: {
                                          score:
                                            _vm.performanceSummaryData.score,
                                          title: "Safety Score",
                                          message:
                                            _vm.performanceSummaryData.message
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "CRow",
                                [
                                  _c(
                                    "CCol",
                                    { attrs: { col: "12" } },
                                    [
                                      _c("GrafanaEmbeddedPanel", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.deviceInfo,
                                            expression: "deviceInfo"
                                          }
                                        ],
                                        key: _vm.loading,
                                        attrs: {
                                          to: _vm.end,
                                          from: _vm.start,
                                          selected: _vm.deviceInfo,
                                          src:
                                            "https://demo1.aarcomm.io/api/grafana/d/SzurgKYVk/test-clean-harbors-route-report-v1?orgId=1"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("CRow", [_c("CCol")], 1),
      _vm.timeline && _vm.timeline.length > 0
        ? _c(
            "div",
            { staticClass: "card", staticStyle: { "min-width": "500px" } },
            _vm._l(_vm.timeline, function(item) {
              return _c(
                "div",
                { key: item.id, staticClass: "p-0 m-0" },
                [
                  item.event
                    ? _c(
                        "CCard",
                        {
                          staticClass: "admin-card",
                          attrs: { collapsed: true, collapsible: true }
                        },
                        [
                          _c(
                            "CCardHeader",
                            [
                              _c("CIcon", {
                                attrs: { name: "cil-location-pin" }
                              }),
                              _c("span", { staticClass: "card-header-text" }, [
                                _vm._v(" Geofence Event ")
                              ]),
                              _c("span", { staticClass: "float-right" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getTime(item.timestamp || item.time)
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "CCardBody",
                            {
                              staticClass: "m-0 p-0 ml-3",
                              on: {
                                dblclick: function($event) {
                                  return _vm.handleViewClick(item)
                                }
                              }
                            },
                            [
                              _c(
                                "CCallout",
                                { attrs: { color: "var(--info)" } },
                                [
                                  _c(
                                    "CMedia",
                                    {
                                      attrs: {
                                        "aside-image-props": {
                                          src: "/Mapbox_dark_sample.jpg",
                                          height: 100,
                                          width: 175
                                        }
                                      }
                                    },
                                    [
                                      _c("h4", [
                                        _c("span", { staticClass: "p-0" }, [
                                          _vm._v(_vm._s(item.event.message))
                                        ])
                                      ]),
                                      _c(
                                        "span",
                                        { staticClass: "p-0" },
                                        [
                                          _c("CGeocode", {
                                            attrs: {
                                              lat: item.event.lat,
                                              lon: item.event.lng
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : item.report
                    ? _c(
                        "CCard",
                        {
                          staticClass: "admin-card mt-0",
                          attrs: { collapsed: false, collapsible: true }
                        },
                        [
                          _c(
                            "CCardHeader",
                            [
                              _c("CIcon", { attrs: { name: "cil-chart" } }),
                              _vm._v(" "),
                              _c("span", { staticClass: "card-header-text" }, [
                                _vm._v(
                                  "Job Report: " + _vm._s(item.report.title)
                                )
                              ]),
                              _c("span", { staticClass: "float-right" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.getTime(item.timestamp)) +
                                    " "
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "CCardBody",
                            [
                              _c(
                                "CCollapse",
                                {
                                  attrs: {
                                    show: Boolean(true),
                                    duration: _vm.collapseDuration
                                  }
                                },
                                [
                                  _c(
                                    "CCardBody",
                                    {
                                      staticClass: "mt-0 p-0",
                                      on: {
                                        dblclick: function($event) {
                                          return _vm.handleViewClick(item)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "CMedia",
                                        {
                                          attrs: {
                                            "aside-image-props": {
                                              src: "/Mapbox_dark_sample.jpg",
                                              height: 100,
                                              width: 175
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "CRow",
                                            [
                                              _c(
                                                "CCol",
                                                [
                                                  _c("h4", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.report.title
                                                        ) +
                                                        " "
                                                    )
                                                  ]),
                                                  _c("div", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-muted"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Date Performed: " +
                                                            _vm._s(
                                                              _vm.getDate(
                                                                item.timestamp
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c("br"),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-muted"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Time In: " +
                                                            _vm._s(
                                                              _vm.getTime(
                                                                item.timestamp
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    item.report.end
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-muted"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Time Out: " +
                                                                _vm._s(
                                                                  _vm.getTime(
                                                                    item.report
                                                                      .end
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]),
                                                  _c(
                                                    "CButton",
                                                    {
                                                      attrs: {
                                                        size: "",
                                                        color: "info"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.handleViewClick(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" View Details ")]
                                                  ),
                                                  false
                                                    ? _c(
                                                        "CButton",
                                                        {
                                                          staticClass: "ml-1",
                                                          attrs: {
                                                            size: "sm",
                                                            color: "danger"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.showWarning(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v(" Delete ")]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _c(
                                                "CCol",
                                                { attrs: { col: "4" } },
                                                [
                                                  _c("CScoreCard", {
                                                    staticClass: "p-0",
                                                    attrs: {
                                                      score: _vm.getPerformanceScore(
                                                        item.report
                                                      ),
                                                      title: "Safety Score"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            0
          )
        : _c(
            "div",
            [
              _c(
                "CCard",
                [_c("CCardBody", [_c("h3", [_vm._v("No Events to Show")])])],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }